import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Header from './shared/layout/header/Header';
import Navbar from 'app/shared/layout/navbar/Navbar';
import { IRootState } from 'app/shared/reducers';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getRegion, getSession, saveSharePageConfiguration } from 'app/shared/reducers/authentication';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getFirebaseToken, messaging } from './firebaseMessaging';
import {
  getCurrentUserSettings,
  setNotificationsToken,
  setUserNotificationToken,
  updateUserSettings,
  setIsHelpVideoPopupOpen,
  setIsSidebarCollapsed,
  setIsFullScreenMode,
} from 'app/entities/user-settings/user-settings.reducer';
import { getNotificationsCount } from './entities/notification/notification.reducer';
import DealerAccountSetupBanner from './components/DealerAccountSetupBanner/DealerAccountSetupBanner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripConfig } from '../stripe';
import { setIsLoginButtonWasPressed } from './modules/account/register/register.reducer';
import { onMessage } from 'firebase/messaging';
import { getDealerForCurrentUser } from 'app/entities/dealer/dealer.reducer';
import ManufacturerAccountSetupBanner from 'app/components/ManufacturerAccountSetupBanner/ManufacturerAccountSetupBanner';
import { getManufacturerForCurrentUser } from 'app/entities/manufacturer/manufacturer.reducer';
import { slide as Menu } from 'react-burger-menu';
import ManufacturerRefAccountSetupBanner from 'app/components/ManufacturerRefAccountSetupBanner/ManufacturerRefAccountSetupBanner';
import ConsultantUserAccountSetupBanner from 'app/components/ConsultantUserAccountSetupBanner/ConsultantUserAccountSetupBanner';
import ConsultantAdminAccountSetupBanner from 'app/components/ConsultantAdminAccountSetupBanner/ConsultantAdminAccountSetupBanner';
import { getConsultantAdminForCurrentUser } from './entities/consultant/consultant.reducer';
import { getConsultantUSerForCurrentUser } from './entities/consultant-user/consultant-user.reducer';
import { getManufacturerRefForCurrentUser } from './entities/manufacturer-ref/manufacturer-ref.reducer';
import { ProSidebarProvider } from 'react-pro-sidebar';
import NavbarResponsive from './shared/layout/navbar/NavbarResponsive';
import { saveConfiguration } from './shared/util/save-configuration';
import { createEntity, createRevitExport } from 'app/entities/user-configuration/user-configuration.reducer';
import VideoGuideModal from './components/VideoGuideModal/VideoGuideModal';
import MD5 from 'crypto-js/md5';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import AccountSetupBanner from './components/AccountSetupBanner/AccountSetupBanner';

const stripePromise = loadStripe(stripConfig.stripe_publishable_key);
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {
  setNotificationsToken;
  updateUserSettings;
}

export const App = (props: IAppProps) => {
  const { account } = props;
  const [marketplaceMode, setMarketplaceMode] = useState('');
  const [navBarMarketplaceMode, setNavBarMarketplaceMode] = useState('');
  const [headerMarketplaceMode, setHeaderMarketplaceMode] = useState('');
  const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState(null);

  onMessage(messaging, payload => {
    toast.success(payload.notification.title + ': ' + payload.notification.body);
  });

  useEffect(() => {
    props.getSession();
    props.getProfile();
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.getNotificationsCount();
      props.getCurrentUserSettings();
      props.getRegion();
      if (props.sharePageConfiguration) {
        fetchConfiguration(props.sharePageConfiguration).then(data => {
          const newEntity = saveConfiguration(data.configuration, null, MD5(props.account.email).toString());
          props.createEntity(newEntity);
          props.saveSharePageConfiguration(null);
        });
      }
      if (props.isLoginButtonWasPressed) {
        getFirebaseToken(setFirebaseToken);
      }
    }
  }, [props.isAuthenticated]);

  useEffect(() => {
    if (firebaseToken) {
      props.setNotificationsToken(firebaseToken);
      props.setUserNotificationToken(firebaseToken);
      props.setIsLoginButtonWasPressed(false);
    }
  }, [firebaseToken]);

  // @ts-ignore
  window.KREATOR_USER = {
    id: account.id,
    user_id: account.login,
    email: account.email,
    name: account.firstName + ' ' + account.lastName,
    city: account.city,
    state: account.state,
    phone: account.phone,
    company: account.company,
    type: account.type,
    zipCode: account.zipCode,
    userCameFrom: account.userCameFrom,
  };

  let ContainerClassName = 'container-fluid';
  let ContentContainerClassName = ' ';
  if (props.isAuthenticated) {
    ContainerClassName += ' auth-container';
    ContentContainerClassName = 'content-container ';
  }
  if (props.isSideBarCollapsed) {
    ContentContainerClassName += 'colapsed-sidebar';
  }
  if (location.pathname.includes('configurators') && !location.pathname.includes('my-configurators')) {
    ContentContainerClassName += ' padding-bottom-zero';
  }
  if (props.isFullScreenMode) {
    ContentContainerClassName += ' padding-zero';
  }
  let NavbarContainerClassName = 'navbar-app-container';
  if (
    hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_DEALER_ADMIN]) &&
    props.account.type === 'ROLE_DEALER_ADMIN' &&
    props.isDealerAccountSetupBannerShown &&
    !props.dealerLoading &&
    props.dealer.status === null
  ) {
    NavbarContainerClassName += ' dealer-banner-margin';
  }

  if (props.isAuthenticated && props.account && !props.account.company) {
    NavbarContainerClassName += ' dealer-banner-margin';
  }

  if (
    hasAnyAuthority(props.account.authorities, [
      AUTHORITIES.ROLE_MANUFACTURER_ADMIN,
      AUTHORITIES.ROLE_CONSULTANT_ADMIN,
      AUTHORITIES.ROLE_CONSULTANT_USER,
      AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN,
    ]) &&
    (props.account.type === 'ROLE_MANUFACTURER_ADMIN' ||
      props.account.type === 'ROLE_CONSULTANT_USER' ||
      props.account.type === 'ROLE_CONSULTANT_ADMIN' ||
      props.account.type === 'ROLE_MANUFACTURER_REP_ADMIN') &&
    props.isManufacturerAccountSetupBannerShown &&
    (!props.manufacturerLoading || !props.consultantLoading || !props.consultantUserLoading || !props.manufacturerRefLoading) &&
    (props.manufacturer.status === null ||
      props.consultant.status === null ||
      props.consultantUser.status === null ||
      props.manufacturerRef.status === null)
  ) {
    NavbarContainerClassName += ' dealer-banner-margin';
  }

  if (
    hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_CONSULTANT_ADMIN]) &&
    props.account.type === 'ROLE_CONSULTANT_ADMIN' &&
    props.isManufacturerAccountSetupBannerShown &&
    !props.consultantUserLoading &&
    props.consultant.status === null
  ) {
    NavbarContainerClassName += ' dealer-banner-margin';
  }

  if (
    hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_CONSULTANT_USER]) &&
    props.account.type === 'ROLE_CONSULTANT_USER' &&
    props.isManufacturerAccountSetupBannerShown &&
    !props.consultantUserLoading &&
    props.consultantUser.status === null
  ) {
    NavbarContainerClassName += ' dealer-banner-margin';
  }

  if (
    hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_MANUFACTURER_REP_ADMIN]) &&
    props.account.type === 'ROLE_MANUFACTURER_REP_ADMIN' &&
    props.isManufacturerAccountSetupBannerShown &&
    !props.manufacturerLoading &&
    props.manufacturerRef.status === null
  ) {
    NavbarContainerClassName += ' dealer-banner-margin';
  }

  useEffect(() => {
    if (props.isMarketplaceMode) {
      setMarketplaceMode(' main-container-marketplace-mode');
      setNavBarMarketplaceMode('d-none');
      setHeaderMarketplaceMode(' header-marketplace-mode');
    } else {
      setMarketplaceMode('');
      setNavBarMarketplaceMode('');
      setHeaderMarketplaceMode('');
    }
  }, [props.isMarketplaceMode]);

  useEffect(() => {
    if (props.isAuthenticated && props.account.type === 'ROLE_DEALER_ADMIN') {
      props.getDealerForCurrentUser();
    }

    if (props.isAuthenticated && props.account.type === 'ROLE_MANUFACTURER_ADMIN') {
      props.getManufacturerForCurrentUser();
    }

    if (props.isAuthenticated && props.account.type === 'ROLE_CONSULTANT_USER') {
      props.getConsultantUSerForCurrentUser();
    }

    if (props.isAuthenticated && props.account.type === 'ROLE_CONSULTANT_ADMIN') {
      props.getConsultantAdminForCurrentUser();
    }

    if (props.isAuthenticated && props.account.type === 'ROLE_MANUFACTURER_REP_ADMIN') {
      props.getManufacturerRefForCurrentUser();
    }
  }, [props.isAuthenticated, props.account]);

  const handleStateChange = state => {
    setIsBurgerMenuOpened(state.isOpen);
  };

  async function fetchConfiguration(sharePageConfiguration) {
    const response = await fetch(`https://api.roomle.com/v2/configurations/${sharePageConfiguration}`);
    const jsonData = await response.json();
    return jsonData;
  }

  return (
    <ProSidebarProvider>
      <Router basename={baseHref}>
        {props.isAuthenticated && props.account && !props.account.company && <AccountSetupBanner />}

        {props.isDealerAccountSetupBannerShown &&
        props.isAuthenticated &&
        props.account.type === 'ROLE_DEALER_ADMIN' &&
        !props.dealerLoading &&
        props.dealer.status === null ? (
          <DealerAccountSetupBanner />
        ) : (
          ''
        )}

        {props.isManufacturerAccountSetupBannerShown &&
        props.isAuthenticated &&
        props.account.type === 'ROLE_MANUFACTURER_ADMIN' &&
        !props.manufacturerLoading &&
        props.manufacturer.status === null ? (
          <ManufacturerAccountSetupBanner />
        ) : (
          ''
        )}

        {props.isManufacturerAccountSetupBannerShown &&
        props.isAuthenticated &&
        props.account.type === 'ROLE_CONSULTANT_ADMIN' &&
        !props.consultantLoading &&
        props.consultant.status === null ? (
          <ConsultantAdminAccountSetupBanner />
        ) : (
          ''
        )}

        {props.isManufacturerAccountSetupBannerShown &&
        props.isAuthenticated &&
        props.account.type === 'ROLE_CONSULTANT_USER' &&
        !props.consultantUserLoading &&
        props.consultantUser.status === null ? (
          <ConsultantUserAccountSetupBanner />
        ) : (
          ''
        )}

        {props.isManufacturerAccountSetupBannerShown &&
        props.isAuthenticated &&
        props.account.type === 'ROLE_MANUFACTURER_REP_ADMIN' &&
        !props.manufacturerRefLoading &&
        props.manufacturerRef.status === null ? (
          <ManufacturerRefAccountSetupBanner />
        ) : (
          ''
        )}

        <VideoGuideModal
          isVideoGuideModalOpen={props.isHelpVideoPopupOpen}
          setIsVideoGuideModalOpen={props.setIsHelpVideoPopupOpen}
          updateUserSettings={props.updateUserSettings}
          currentUserSettings={props.currentUserSettings}
        />

        <div className={NavbarContainerClassName}>
          <ToastContainer position={toast.POSITION.TOP_RIGHT} className="toastify-container" toastClassName="toastify-toast" />
          {props.isAuthenticated && (
            <>
              <div className={'navbar-normal-mode' + ' ' + navBarMarketplaceMode}>
                <Navbar
                  account={props.account}
                  ribbonEnv={props.ribbonEnv}
                  isInProduction={props.isInProduction}
                  isAuthenticated={props.isAuthenticated}
                  projectOpened={Object.keys(props.currentProject).length > 3}
                  isSwaggerEnabled={props.isSwaggerEnabled}
                  setIsSidebarCollapsed={props.setIsSidebarCollapsed}
                  notificationsCount={props.notificationsCount}
                />
              </div>

              <Menu width={'100%'} isOpen={isBurgerMenuOpened} className="app-burger-menu" onStateChange={handleStateChange}>
                <NavbarResponsive
                  account={props.account}
                  ribbonEnv={props.ribbonEnv}
                  isInProduction={props.isInProduction}
                  isAuthenticated={props.isAuthenticated}
                  isSwaggerEnabled={props.isSwaggerEnabled}
                  projectOpened={Object.keys(props.currentProject).length > 3}
                  closeBurgerMenu={() => setIsBurgerMenuOpened(false)}
                  notificationsCount={props.notificationsCount}
                />
              </Menu>
            </>
          )}

          <div className={ContainerClassName + marketplaceMode} id="app-view-container">
            {props.isAuthenticated && (
              <ErrorBoundary>
                <Header
                  firstName={props.account.firstName}
                  lastName={props.account.lastName}
                  email={props.account.email}
                  marketplaceMode={headerMarketplaceMode}
                  account={props.account}
                  region={props.region}
                  openBurgerMenu={() => setIsBurgerMenuOpened(true)}
                  isSideBarCollapsed={props.isSideBarCollapsed}
                  notificationsCount={props.notificationsCount?.notification}
                />
              </ErrorBoundary>
            )}
            <Elements stripe={stripePromise}>
              <div className={ContentContainerClassName}>
                <ErrorBoundary>
                  <AppRoutes />
                </ErrorBoundary>
              </div>
            </Elements>
          </div>
        </div>
      </Router>
    </ProSidebarProvider>
  );
};

const mapStateToProps = ({
  authentication,
  applicationProfile,
  userConfiguration,
  userSettings,
  register,
  dealer,
  manufacturer,
  consultant,
  consultantUser,
  manufacturerRef,
  project,
  notification,
}: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account,
  region: authentication.region,
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  isMarketplaceMode: userConfiguration.marketplaceMode,
  currentUserSettings: userSettings.currentUserSettings,
  isHelpVideoPopupOpen: userSettings.isHelpVideosModalOpen,
  isDealerAccountSetupBannerShown: register.isDealerAccountSetupBannerShown,
  isManufacturerAccountSetupBannerShown: register.isManufacturerAccountSetupBannerShown,
  isLoginButtonWasPressed: register.isLoginButtonWasPressed,
  dealer: dealer.entity,
  dealerLoading: dealer.loading,
  manufacturer: manufacturer.entity,
  manufacturerLoading: manufacturer.loading,
  consultant: consultant.entity,
  consultantLoading: consultant.loading,
  consultantUser: consultantUser.entity,
  consultantUserLoading: consultantUser.loading,
  manufacturerRef: manufacturerRef.entity,
  manufacturerRefLoading: manufacturerRef.loading,
  currentProject: project.entity,
  sharePageConfiguration: authentication.sharedPageConfiguration,
  notificationsCount: notification.notificationsCount,
  isSideBarCollapsed: userSettings.isSidebarCollapsed,
  isFullScreenMode: userSettings.isFullScreenMode,
});

const mapDispatchToProps = {
  getRegion,
  getSession,
  getProfile,
  getCurrentUserSettings,
  setNotificationsToken,
  setUserNotificationToken,
  setIsLoginButtonWasPressed,
  getDealerForCurrentUser,
  getManufacturerForCurrentUser,
  getConsultantAdminForCurrentUser,
  getConsultantUSerForCurrentUser,
  getManufacturerRefForCurrentUser,
  createEntity,
  createRevitExport,
  saveSharePageConfiguration,
  updateUserSettings,
  getNotificationsCount,
  setIsHelpVideoPopupOpen,
  setIsSidebarCollapsed,
  setIsFullScreenMode,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
