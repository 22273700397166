import { IProduct } from 'app/shared/model/product.model';

export const enum ProfileStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export const enum MrfRefEmailType {
  BY_REGION = 'BY_REGION',
  BY_STATE = 'BY_STATE',
}

export interface IManufacturer {
  id?: number;
  name?: string;
  enabled?: boolean;
  websiteURL?: string;
  companyLogo?: string;
  city?: string;
  state?: string;
  businessName?: string;
  skills?: string;
  qualifySpecialStatus?: string;
  cardNumber?: string;
  cardHolder?: string;
  customerId?: string;
  month?: string;
  year?: string;
  cvc?: string;
  status?: ProfileStatus;
  refManufacturerId?: string;
  subscriptionId?: string;
  payPerUsagesSubscriptionId?: string;
  apiKey?: string;
  adminEmail?: string;
  hubspotEnable?: boolean;
  hubspotApiKey?: string;
  sendgridEnabled?: boolean;
  sendgridApiKey?: string;
  showMrfInventoryDetails?: boolean;
  shareRevitTemplate?: string;
  shareRevitTemplateFrom?: string;
  shareRevitTemplateCc?: string;
  shareRevitTemplateBcc?: string;
  shareConfigurationTemplate?: string;
  shareConfigurationFrom?: string;
  shareConfigurationCc?: string;
  shareConfigurationBcc?: string;
  projectTemplateUser?: string;
  projectTemplateUserFrom?: string;
  projectTemplateUserCc?: string;
  projectTemplateUserBcc?: string;
  projectTemplateUserSendTwoEmail?: boolean;
  projectTemplateManufacturer?: string;
  projectTemplateManufacturerFrom?: string;
  projectTemplateManufacturerTo?: string;
  projectTemplateManufacturerCc?: string;
  projectTemplateManufacturerBcc?: string;
  floorPlanUserTemplate?: string;
  floorPlanUserFrom?: string;
  floorPlanUserCc?: string;
  floorPlanUserBcc?: string;
  floorPlanUserSendTwoEmail?: boolean;
  floorPlanManufacturerTemplate?: string;
  floorPlanManufacturerFrom?: string;
  floorPlanManufacturerTo?: string;
  floorPlanManufacturerCc?: string;
  floorPlanManufacturerBcc?: string;
  downloadDrawingTemplate?: string;
  downloadDrawingFrom?: string;
  downloadDrawingCc?: string;
  downloadDrawingBcc?: string;
  sharpSpringEnable?: boolean;
  sharpSpringAccountID?: string;
  sharpSpringSecretKey?: string;
  mrfRefEmailType?: MrfRefEmailType;
  adminEmails?: any;
  attachWrittenSpecification?: boolean;
  supportEmail?: string;
  products?: IProduct[];
  userLogin?: string;
  userId?: number;
}

export const defaultValue: Readonly<IManufacturer> = {
  enabled: false,
  hubspotEnable: false,
  sendgridEnabled: false,
  showMrfInventoryDetails: false,
  projectTemplateUserSendTwoEmail: false,
  floorPlanUserSendTwoEmail: false,
  sharpSpringEnable: false,
  mrfRefEmailType: MrfRefEmailType.BY_STATE,
  attachWrittenSpecification: false,
};
