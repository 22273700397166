import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import BaseLayout from '../BaseLayout';
import AddFloorPlanCard from 'app/components/AddFloorPlanCard/AddFloorPlanCard';
import Search from 'app/components/Search/Search';
import FloorPlanCard from 'app/components/FloorPlanCard/FloorPlanCard';
import CardListToggle from 'app/components/RoomDesignerProjectsTable/CardListToggle';
import AppSpinner from 'app/components/Spinner/Spinner';
import {
  getAllProjectByLoginUser,
  deleteEntity,
  forgeConnect,
  shareProject,
  getProject,
  updateProject,
  updateProjectDetails,
} from 'app/entities/project/project.reducer';

const ShareConfigurationByEmailModal = lazy(() => import('app/components/ShareConfigurationByeEmailModal/ShareConfigurationByEmailFolder'));
const CreateProjectNameModal = lazy(() => import('app/components/CreateProjecNameModal/CreateProjectNameModal'));
const ProjectDetailsModal = lazy(() => import('app/components/ProjectDetailsModal/ProjectDetailsModal'));
const CreateShopDrawingsModal = lazy(() => import('app/components/CreateShopDrawingsModal/CreateShopDrawingsModal'));
const ConfirmationDialog = lazy(() => import('app/components/ConfirmationDialog/ConfirmationDialog'));
const CreateProjectModal = lazy(() => import('app/components/CreateProjectModal/CreateProjectModal'));
const ProjectsTable = lazy(() => import('app/components/RoomDesignerProjectsTable/ProjectsTable'));
const ProjectCodeInputModal = lazy(() => import('app/components/ProjectCodeInputModal/ProjectCodeInputModal'));
import NoDataBanner from 'app/components/NoDataBanner/NoDataBanner';
import DeleteAlert from 'app/components/DeleteAlert/DeleteAlert';
import MainThemeButton from 'app/components/MainThemeButton/MainThemeButton';
import ReactPaginate from 'react-paginate';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'reactstrap';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { useCreateDrawings } from 'app/customHooks/useProjectDrawings';
import { setIsHelpVideoPopupOpen } from 'app/entities/user-settings/user-settings.reducer';
import MD5 from 'crypto-js/md5';
import './roomDesigner.scss';
import { IProject } from 'app/shared/model/project.model';
import { spawn } from 'child_process';

export const RoomDesigner = props => {
  const location = useLocation();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'dateCreated',
        order: 'desc',
        activePage: 0,
      },
      props.location.search
    )
  );
  const history = useHistory();
  const [isUseTableView, setIsUseTableView] = useState<boolean>(false);
  const [shareEmail, setShareEmail] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectObject, setSelectedProjectObject] = useState(null);
  const [isCreateWithFloorPlan, setIsCreateWithFloorPlan] = useState<boolean>(true);
  const [mailsha1, setMailsha1] = useState(MD5(props.account.email).toString());
  const [isOpenProjectWasPressed, setIsOpenProjectWasPressed] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const {
    projectCodeValue,
    setProjectCodeValue,
    isProjectCodeInputModalOpen,
    setIsProjectCodeInputModalOpen,
    isCreateDrawingsModalOpen,
    setIsCreateDrawingsModalOpen,
    createDrawings,
    updateProjectCode,
    // isEmptyFieldsData,
    // setIsEmptyFieldsData,
  } = useCreateDrawings(props.currentProject, props.account, props.forgeConnect, props.updateProject, props.updateProjectDetails);

  // modal windows
  const [isShareConfigurationModalOpen, setIsShareConfigurationModalOpen] = useState<boolean>(false);
  const [isCreateNewProjectModalOpen, setIsCreateNewProjectModalOpen] = useState<boolean>(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState<boolean>(false);
  const [isProjectDetailsModalOpen, setIsProjectDetailsModalOpen] = useState<boolean>(false);
  const [isCreateProjectNameModalOpen, setIsCreateProjectNameModalOpen] = useState<boolean>(false);
  const [isOpenProjectDetailsButtonWasPressed, setIsOpenProjectDetailsButtonWasPressed] = useState<boolean>(false);

  // pagination start
  const getAllEntities = () => {
    props.getAllProjectByLoginUser(
      paginationState.activePage,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      searchValue
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (!searchValue) {
      sortEntities();
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      sortEntities();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [paginationState.activePage, paginationState.order, paginationState.sort, searchValue]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage.selected,
    });
  };
  // pagination end

  useEffect(() => {
    if (Object.keys(props.currentProject).length > 3 && isOpenProjectWasPressed) {
      handleProjectOpening(props.currentProject);
    }

    if (props.currentProject.projectCode) {
      setSelectedProjectObject(props.currentProject);
    }
  }, [props.currentProject]);

  useEffect(() => {
    if (isProjectDetailsModalOpen || isCreateDrawingsModalOpen) {
      props.getProject(selectedProjectId);
    }
  }, [isProjectDetailsModalOpen, isCreateDrawingsModalOpen]);

  const deleteProject = useCallback(() => {
    props.deleteEntity(selectedProjectObject.id);
    setSelectedProjectObject(null);
    setIsDeleteConfirmationDialogOpen(false);
  }, [props.deleteEntity, selectedProjectObject]);

  const shareConfiguration = useCallback(() => {
    props.shareProject({ projectId: selectedProjectId, shareEmail: shareEmail });
    setIsShareConfigurationModalOpen(false);
    setSelectedProjectId(null);
    setSelectedProjectObject(null);
  }, [props.shareProject, selectedProjectId, shareEmail]);

  const openProject = useCallback(
    (id: number) => {
      setIsOpenProjectWasPressed(true);
      props.getProject(id);
    },
    [props.getProject]
  );

  const handleProjectOpening = (project: IProject) => {
    if (project.isRoomDesign) {
      history.push(`/edit-project/${project.id}`);
    } else if (project.projectProducts?.[0]?.userConfiguration) {
      const { roomleComponentId, id, configuration } = project.projectProducts[0].userConfiguration;
      const path = `configurators/${mailsha1}/${id}/${roomleComponentId}/${configuration}`;
      history.push({
        pathname: path,
        state: { prevPath: location.pathname },
      });
      sessionStorage.setItem('currentProject', String(project.id));
    } else {
      history.push('/');
    }
  };

  const handleCreateNewProjectButton = useCallback(() => {
    props.isUseMOC ? setIsCreateNewProjectModalOpen(true) : setIsCreateProjectNameModalOpen(true);
  }, [props.isUseMOC]);

  return (
    <BaseLayout
      title={'My Projects'}
      toggle={window.screen.width >= 1024 && <CardListToggle setIsUseTableView={setIsUseTableView} isUseTableView={isUseTableView} />}
      search={<Search searchValue={searchValue} setSearchValue={setSearchValue} />}
      createButton={
        <div>
          <MainThemeButton onClick={handleCreateNewProjectButton} title={'Create Project'} iconName="plus" />
        </div>
      }
    >
      {props.projects.length !== 0 && !props.loadingProjectsList && (
        <div className="projects-cards-container">
          {!isUseTableView && props.projects.length && <AddFloorPlanCard onClick={handleCreateNewProjectButton} />}
          {isUseTableView ? (
            <Suspense fallback={<span></span>}>
              <ProjectsTable
                projects={props.projects}
                setSelectedProjectId={setSelectedProjectId}
                setIsExportRevitModalOpen={setIsShareConfigurationModalOpen}
                setIsCreateDrawingsModalOpen={setIsCreateDrawingsModalOpen}
                setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
                setSelectedProjectObject={setSelectedProjectObject}
                setIsProjectDetailsModalOpen={setIsProjectDetailsModalOpen}
                openProject={openProject}
                setIsOpenProjectDetailsButtonWasPressed={setIsOpenProjectDetailsButtonWasPressed}
              />
            </Suspense>
          ) : (
            props.projects.map(item => (
              <FloorPlanCard
                project={item}
                openProject={openProject}
                key={item.id}
                forgeConnect={props.forgeConnect}
                setSelectedProjectId={setSelectedProjectId}
                setIsExportRevitModalOpen={setIsShareConfigurationModalOpen}
                setIsCreateDrawingsModalOpen={setIsCreateDrawingsModalOpen}
                setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
                setSelectedProjectObject={setSelectedProjectObject}
                setIsProjectDetailsModalOpen={setIsProjectDetailsModalOpen}
                setIsOpenProjectDetailsButtonWasPressed={setIsOpenProjectDetailsButtonWasPressed}
              />
            ))
          )}
        </div>
      )}
      {props.loadingProjectsList && !isOpenProjectDetailsButtonWasPressed && <AppSpinner />}
      {!props.loadingProjectsList && props.projects.length === 0 && <NoDataBanner title="You have no Projects to display yet!" />}
      <>
        {props.totalItems > ITEMS_PER_PAGE && !props.loadingProjectsList ? (
          <Row className="justify-content-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePagination}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={Math.ceil(props.totalItems / paginationState.itemsPerPage)}
              previousLabel="<"
              renderOnZeroPageCount={null}
              forcePage={paginationState.activePage}
              className="pagination"
              pageClassName="pagination-page"
              previousClassName="pagination-nex-prev-button"
              nextClassName="pagination-nex-prev-button"
              disabledClassName="pagination-page-disabled"
              activeClassName="pagination-active-page"
            />
          </Row>
        ) : (
          ''
        )}
      </>
      <Suspense fallback={<span></span>}>
        <CreateProjectModal
          isOpen={isCreateNewProjectModalOpen}
          closeModal={() => setIsCreateNewProjectModalOpen(false)}
          setIsCreateProjectNameModalOpen={setIsCreateProjectNameModalOpen}
          setIsCreateWithFloorPlan={setIsCreateWithFloorPlan}
        />
        <CreateProjectNameModal
          isOpen={isCreateProjectNameModalOpen}
          closeModal={() => setIsCreateProjectNameModalOpen(false)}
          isCreateWithFloorPlan={isCreateWithFloorPlan}
        />
        <ShareConfigurationByEmailModal
          isOpen={isShareConfigurationModalOpen}
          setShareEmail={setShareEmail}
          shareConfiguration={shareConfiguration}
          setIsShareConfigurationByEmailModalOpen={() => setIsShareConfigurationModalOpen(false)}
          saveTo="Save Project"
          setIsHelpVideoPopupOpen={props.setIsHelpVideoPopupOpen}
        />
        <CreateShopDrawingsModal
          isOpen={isCreateDrawingsModalOpen}
          setIsCreateDrawingsModalOpen={() => {
            setIsCreateDrawingsModalOpen(false);
          }}
          handleCreateDrawingsButton={createDrawings}
          loadingProject={props.loading}
        />
        <ConfirmationDialog
          isOpen={isDeleteConfirmationDialogOpen}
          closeConfirmationDialog={() => setIsDeleteConfirmationDialogOpen(false)}
          confirmationInfo={`Are you sure you want to delete the
            ${selectedProjectObject?.projectName} project?`}
          aditionalConfirmationInfo={<DeleteAlert />}
          confirmAction={deleteProject}
        />
        <ProjectDetailsModal
          project={props.currentProject}
          isOpen={isProjectDetailsModalOpen}
          closeModal={() => {
            setIsProjectDetailsModalOpen(false);
            setSelectedProjectObject(null);
            setSelectedProjectId(null);
            setIsOpenProjectDetailsButtonWasPressed(false);
          }}
          updateProjectDetails={props.updateProjectDetails}
          projectLoading={props.loading}
          projectUpdating={props.updatingProject}
          projectUpdateSuccess={props.projectUpdateSuccess}
        />
        <ProjectCodeInputModal
          isOpen={isProjectCodeInputModalOpen}
          closeModal={setIsProjectCodeInputModalOpen}
          projectCodeValue={projectCodeValue}
          setProjectCodeValue={setProjectCodeValue}
          updateProjectCode={updateProjectCode}
        />
      </Suspense>
    </BaseLayout>
  );
};

const mapStateToProps = ({ project, userConfiguration, authentication, userSettings }: IRootState) => ({
  projects: project.entities,
  projectUpdateSuccess: project.updateSuccess,
  loading: project.loading,
  updatingProject: project.updating,
  loadingProjectsList: project.loadingProjectsList,
  currentProject: project.entity,
  updating: userConfiguration.updating,
  updateSuccess: project.updateSuccess,
  account: authentication.account,
  totalItems: project.totalItems,
  isUseMOC: userSettings?.currentUserSettings?.projectStartWith,
});

const mapDispatchToProps = {
  getAllProjectByLoginUser,
  forgeConnect,
  deleteEntity,
  shareProject,
  getProject,
  updateProject,
  updateProjectDetails,
  setIsHelpVideoPopupOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomDesigner);
