import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './manufacturer.reducer';
import ManufacturerFileUpload from 'app/entities/manufacturer/ManufacturerFileUpload';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Field, Form as ReactFinalForm } from 'react-final-form';
import AdminEmailSettings from 'app/entities/manufacturer/AdminEmailSettings';
import { getMfrRepByMfrAndRegion, getRegions, getStates } from 'app/entities/manufacturer-ref-data/manufacturer-ref-data.reducer';

export interface IManufacturerUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

interface IadminEmails {
  regionOrState: string;
  email: string;
}

export const ManufacturerUpdate = (props: IManufacturerUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [adminEmails, setAdminEmails] = useState<IadminEmails[]>([]);
  const [regionOrState, setRegionOrStateOrState] = useState('');
  const [email, setEmail] = useState('');
  const [emailType, setEmailType] = useState('DROPDOWN');

  const { manufacturerEntity, loading, updating } = props;
  const handleClose = () => {
    props.history.push('/manufacturer' + props.location.search);
  };
  useEffect(() => {
    if (manufacturerEntity.id) {
      props.getStates();
      props.getRegions();
      props.getMfrRepByMfrAndRegion(manufacturerEntity.name, null);
    }
  }, [manufacturerEntity]);

  const getManufacturerRefByRegions = regionData => {
    if (manufacturerEntity.id) {
      props.getMfrRepByMfrAndRegion(manufacturerEntity.name, regionData);
    }
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (!props.loading && props.manufacturerEntity.id) {
      setAdminEmails(
        props.manufacturerEntity.adminEmails != null && props.manufacturerEntity.adminEmails !== ''
          ? JSON.parse(props.manufacturerEntity.adminEmails)
          : []
      );
    }
  }, [props.manufacturerEntity, props.loading]);

  const saveEntity = values => {
    const entity = {
      ...manufacturerEntity,
      ...values,
      adminEmails: JSON.stringify(adminEmails),
    };

    if (isNew) {
      props.createEntity(entity);
    } else {
      props.updateEntity(entity);
    }
  };
  const identity = value => value;
  const required = value => (value ? undefined : <small color="danger">This field is required</small>);

  const handleRemove = index => {
    const newAdminEmails = [...adminEmails.filter((item, i) => i !== index)];
    setAdminEmails(newAdminEmails);
  };
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="familyBuilderApiApp.manufacturer.home.createOrEditLabel">Create or edit a Manufacturer</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <ReactFinalForm
                initialValues={manufacturerEntity}
                onSubmit={saveEntity}
                validate={values => {
                  const errors = {};
                  return errors;
                }}
                render={({ handleSubmit, form, values }) => {
                  useEffect(() => {
                    if (values.mrfRefEmailType == 'BY_REGION') {
                      setRegionOrStateOrState('');
                      setEmail('');
                      setAdminEmails([]);
                    }
                  }, [values.mrfRefEmailType]);
                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Manufacturer Details</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="userId">User ID</Label>
                                  <Input {...input}></Input>
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="userId"
                              validate={required}
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Name</Label>
                                  <Input {...input}></Input>
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="name"
                              validate={required}
                            />
                          </FormGroup>
                          <FormGroup className="mb-3 d-flex ml-3 mt-3">
                            <Label for="exampleEmail">Enable</Label>
                            <Field
                              render={({ input, meta }) => (
                                <>
                                  <Input
                                    type={'checkbox'}
                                    checked={input.value}
                                    onChange={event => input.onChange(event.target.checked)}
                                    {...input}
                                  />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </>
                              )}
                              parse={identity}
                              placeholder={''}
                              type="checkbox"
                              name="enabled"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Company Logo</Label>
                                  <ManufacturerFileUpload setOutput={data => input.onChange(data)} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="companyLogo"
                              validate={required}
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="supportEmail">Support Email</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="supportEmail"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="city">City</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="city"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="state">State</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="state"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3 d-flex ml-3 mt-3">
                            <Label for="exampleEmail">Show Mfr in Inventory Details</Label>
                            <Field
                              render={({ input, meta }) => (
                                <>
                                  <Input
                                    type={'checkbox'}
                                    checked={input.value}
                                    onChange={event => input.onChange(event.target.checked)}
                                    {...input}
                                  />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </>
                              )}
                              parse={identity}
                              placeholder={''}
                              type="checkbox"
                              name="showMrfInventoryDetails"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Website URL</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="websiteURL"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Admin Email</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="adminEmail"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Hubspot</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3 d-flex ml-3 mt-3">
                            <Label for="exampleEmail">Hubspot Enable</Label>
                            <Field
                              render={({ input, meta }) => (
                                <>
                                  <Input
                                    type={'checkbox'}
                                    checked={input.value}
                                    onChange={event => input.onChange(event.target.checked)}
                                    {...input}
                                  />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </>
                              )}
                              parse={identity}
                              placeholder={''}
                              type="checkbox"
                              name="hubspotEnable"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Hubspot API Key</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="hubspotApiKey"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3 d-flex ml-3 mt-3">
                            <Label for="exampleEmail">SendGrid Enable</Label>
                            <Field
                              render={({ input, meta }) => (
                                <>
                                  <Input
                                    type={'checkbox'}
                                    checked={input.value}
                                    onChange={event => input.onChange(event.target.checked)}
                                    {...input}
                                  />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </>
                              )}
                              parse={identity}
                              placeholder={''}
                              type="checkbox"
                              name="sendgridEnabled"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Sendgrid API Key</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="sendgridApiKey"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Sharp spring</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3 d-flex ml-3 mt-3">
                            <Label for="exampleEmail">SharpSpring Enable</Label>
                            <Field
                              render={({ input, meta }) => (
                                <>
                                  <Input
                                    type={'checkbox'}
                                    checked={input.value}
                                    onChange={event => input.onChange(event.target.checked)}
                                    {...input}
                                  />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </>
                              )}
                              parse={identity}
                              placeholder={''}
                              type="checkbox"
                              name="sharpSpringEnable"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">SharpSpring Account ID</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="sharpSpringAccountID"
                            />
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">SharpSpring Secret Key</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="sharpSpringSecretKey"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Share Revit Template Setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Share Revit Template</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareRevitTemplate"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="shareRevitTemplateFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareRevitTemplateFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="shareRevitTemplateCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareRevitTemplateCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="shareRevitTemplateBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareRevitTemplateBcc"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b> Share Configuration Template Setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="exampleEmail">Share Configuration Template</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareConfigurationTemplate"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="shareConfigurationFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareConfigurationFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="shareConfigurationCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareConfigurationCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="shareConfigurationBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="shareConfigurationBcc"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>User Project Template Setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateUser">User Project Template ID</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateUser"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateUserFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateUserFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateUserCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateUserCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateUserBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateUserBcc"
                            />
                          </FormGroup>

                          <div className={'d-flex '}>
                            <FormGroup className="mb-3 d-flex ml-3 mt-3">
                              <Label for="projectTemplateUserSendTwoEmail">Send Two Email</Label>
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="projectTemplateUserSendTwoEmail"
                              />
                            </FormGroup>
                            &nbsp;&nbsp;&nbsp;
                            <FormGroup className="mb-3 d-flex ml-3 mt-3">
                              <Label for="attachWrittenSpecification">Attach written specification</Label>
                              <Field
                                render={({ input, meta }) => (
                                  <>
                                    <Input
                                      type={'checkbox'}
                                      checked={input.value}
                                      onChange={event => input.onChange(event.target.checked)}
                                      {...input}
                                    />
                                    {meta.touched && meta.error && <span>{meta.error}</span>}
                                  </>
                                )}
                                parse={identity}
                                placeholder={''}
                                type="checkbox"
                                name="attachWrittenSpecification"
                              />
                            </FormGroup>
                          </div>
                        </CardBody>
                      </Card>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Manufacturer Project Template Setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateManufacturer">Manufacturer Project Template ID</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateManufacturer"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateManufacturerFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateManufacturerFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateManufacturerTo">To</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateManufacturerTo"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateManufacturerCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateManufacturerCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="projectTemplateManufacturerBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="projectTemplateManufacturerBcc"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b> User Floor Plan Template Setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanUserTemplate">User Floor Plan Template ID</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanUserTemplate"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanUserFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanUserFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanUserCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanUserCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanUserBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanUserBcc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3 d-flex ml-3 mt-3">
                            <Label for="floorPlanUserSendTwoEmail">Send Two Email</Label>
                            <Field
                              render={({ input, meta }) => (
                                <>
                                  <Input
                                    type={'checkbox'}
                                    checked={input.value}
                                    onChange={event => input.onChange(event.target.checked)}
                                    {...input}
                                  />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </>
                              )}
                              parse={identity}
                              placeholder={''}
                              type="checkbox"
                              name="floorPlanUserSendTwoEmail"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Manufacturer Floor Plan Setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanManufacturerTemplate">Manufacturer Floor Plan Template ID</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanManufacturerTemplate"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanManufacturerFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanManufacturerFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanManufacturerTo">To</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanManufacturerTo"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanManufacturerCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanManufacturerCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="floorPlanManufacturerBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="floorPlanManufacturerBcc"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br></br>
                      <Card>
                        <CardHeader>
                          <b>Download drawing template setup</b>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="downloadDrawingTemplate">Download drawing Template ID</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="downloadDrawingTemplate"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="downloadDrawingFrom">From</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="downloadDrawingFrom"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="downloadDrawingCc">CC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="downloadDrawingCc"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Field
                              render={({ input, meta }) => (
                                <div className={'w-100'}>
                                  <Label for="downloadDrawingBcc">BCC</Label>
                                  <Input {...input} />
                                  {meta.touched && meta.error && <span>{meta.error}</span>}
                                </div>
                              )}
                              parse={identity}
                              placeholder={''}
                              name="downloadDrawingBcc"
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <br></br>

                      <Card>
                        <CardBody>
                          <div className="d-flex justify-content-center align-items-end">
                            <div style={{ width: '45%', marginLeft: '20px' }}>
                              <div>
                                <label>
                                  <Field
                                    checked={values.mrfRefEmailType === 'BY_STATE'}
                                    name="mrfRefEmailType"
                                    component="input"
                                    type="radio"
                                    value="BY_STATE"
                                  />{' '}
                                  &nbsp;By State
                                </label>
                                &nbsp;&nbsp;
                                <label>
                                  <Field
                                    checked={values.mrfRefEmailType === 'BY_REGION'}
                                    name="mrfRefEmailType"
                                    component="input"
                                    type="radio"
                                    value="BY_REGION"
                                  />{' '}
                                  &nbsp;By Region
                                </label>
                              </div>
                              {values.mrfRefEmailType == 'BY_REGION' ? (
                                <Input
                                  value={regionOrState}
                                  type="select"
                                  className="email-settings-project-select"
                                  onChange={event => {
                                    const regionData = event.target.value;
                                    setRegionOrStateOrState(regionData);
                                    getManufacturerRefByRegions(regionData);
                                  }}
                                >
                                  <option value={null}>Select a region</option>
                                  {props.regions.length > 0
                                    ? props.regions.map(item => {
                                        return <option value={item}>{item}</option>;
                                      })
                                    : ''}
                                </Input>
                              ) : (
                                <Input
                                  value={regionOrState}
                                  type="select"
                                  className="email-settings-project-select"
                                  onChange={event => {
                                    const regionData = event.target.value;
                                    setRegionOrStateOrState(regionData);
                                  }}
                                >
                                  <option value={null}>Select a state</option>
                                  {props.states.length > 0
                                    ? props.states.map(item => {
                                        return <option value={item}>{item}</option>;
                                      })
                                    : ''}
                                </Input>
                              )}
                            </div>
                            <div style={{ width: '45%', marginLeft: '20px' }}>
                              <div style={{ marginLeft: '20px' }}>
                                <label>
                                  <Input
                                    checked={emailType === 'DROPDOWN'}
                                    component="input"
                                    type="radio"
                                    value="DROPDOWN"
                                    onChange={() => setEmailType('DROPDOWN')}
                                  />{' '}
                                  &nbsp;Select From Dropdown
                                </label>
                                &nbsp;&nbsp;
                                <label>
                                  <Input
                                    checked={emailType === 'MANUAL_SELECTION'}
                                    component="input"
                                    type="radio"
                                    value="MANUAL_SELECTION"
                                    onChange={() => setEmailType('MANUAL_SELECTION')}
                                  />{' '}
                                  &nbsp;Manual Selection
                                </label>
                              </div>

                              {emailType === 'DROPDOWN' ? (
                                <Input
                                  value={email}
                                  type="select"
                                  className="email-settings-project-select"
                                  onChange={event => setEmail(event.target.value)}
                                >
                                  <option value={null}>Select a region/state admin</option>
                                  {props.manufacturerRepEntities.length > 0
                                    ? props.manufacturerRepEntities.map(item => {
                                        return (
                                          <option value={item.email}>
                                            {item.email} - {item.company}, {item.mailingAddress}, {item.city}, {item.country}
                                          </option>
                                        );
                                      })
                                    : ''}
                                </Input>
                              ) : (
                                <Input
                                  value={email}
                                  type="text"
                                  placeholder="Enter email"
                                  className="email-settings-project-select"
                                  onChange={event => setEmail(event.target.value)}
                                ></Input>
                              )}
                            </div>
                            <div style={{ width: '10%', marginLeft: '10px' }}>
                              <Button
                                color="primary"
                                className="add-email-settings-project-button"
                                disabled={!regionOrState || !email}
                                onClick={() => {
                                  if (regionOrState && email) {
                                    setAdminEmails([...adminEmails, { regionOrState: regionOrState, email: email }]);
                                    setRegionOrStateOrState('');
                                    setEmail('');
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon="plus" />
                              </Button>
                            </div>
                          </div>

                          <br></br>
                          <Table responsive borderless>
                            <thead>
                              <tr>
                                <th>Region</th>
                                <th>Admin Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              {adminEmails.map((item, index) => (
                                <AdminEmailSettings index={index} item={item} key={index} handleRemove={handleRemove} />
                              ))}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                      <br></br>
                      <div>
                        <Button tag={Link} id="cancel-save" to="/manufacturer" replace color="info">
                          <FontAwesomeIcon icon="arrow-left" />
                          &nbsp;
                          <span className="d-none d-md-inline">Back</span>
                        </Button>
                        &nbsp;
                        <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                          <FontAwesomeIcon icon="save" />
                          &nbsp; Save
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  manufacturerEntity: storeState.manufacturer.entity,
  loading: storeState.manufacturer.loading,
  updating: storeState.manufacturer.updating,
  updateSuccess: storeState.manufacturer.updateSuccess,

  states: storeState.manufacturerRefData.states,
  regions: storeState.manufacturerRefData.regions,
  loadingRegions: storeState.manufacturerRefData.loadingRegions,
  userRegion: storeState.authentication.region,
  manufacturerRep: storeState.manufacturerRefData.entity,
  manufacturerRepEntities: storeState.manufacturerRefData.entities,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,

  getRegions,
  getStates,
  getMfrRepByMfrAndRegion,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerUpdate);
