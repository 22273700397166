// @ts-nocheck
import { getEntityByComponentId } from 'app/entities/product/product.reducer';
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
  shareConfiguration,
  createRevitExport,
} from 'app/entities/user-configuration/user-configuration.reducer';
import { IRootState } from 'app/shared/reducers';
import Roomle from 'app/shared/util/roomle';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import MD5 from 'crypto-js/md5';
import ShareConfigurationByEmailModal from 'app/components/ShareConfigurationByeEmailModal/ShareConfigurationByEmailFolder';
import ConfigurationCartTable from 'app/components/ConfigurationCart/ConfigurationCartTable';
import RoomleConfigurationBar from 'app/components/RoomleConfigurationBar/RoomleConfigurationBar';
import MarketplaceButtonsModal from 'app/components/RoomleMarketplaceModal/MarketplaceModal';
import AddToProjectModal from 'app/components/AddToProjectModal/AddToProjectModal';
import {
  getAllProjectByLoginUser,
  createProject,
  updateProject,
  updateProjectDetails,
  resetCurrentProject,
  forgeConnect,
  getProject,
  setIsCurrentProjectShouldBeClosed,
  setFloorPlanIdForDirectAdding,
} from '../project/project.reducer';
import { deleteProjectProduct } from '../project-product/project-product.reducer';
import ChangeConfigurationModal from 'app/components/ChangeConfigurationModal/ChangeConfigurationModal';
import ConfigurationProjectBar from 'app/components/ConfigurationProjectBar/ConfigurationProjectBar';
import ProjectDetailsModal from 'app/components/ProjectDetailsModal/ProjectDetailsModal';
import CreateShopDrawingsModal from 'app/components/CreateShopDrawingsModal/CreateShopDrawingsModal';
import InventoryDetailsModal from 'app/components/InventoryDetailsModal/InventoryDetailsModal';
import ConfirmationDialog from 'app/components/ConfirmationDialog/ConfirmationDialog';
import DeleteAlert from 'app/components/DeleteAlert/DeleteAlert';
import CreateProjectNameModal from 'app/components/CreateProjecNameModal/CreateProjectNameModal';
import AppSpinner from 'app/components/Spinner/Spinner';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ForbiddenExportModal from 'app/components/ExportRevitModal/ForbiddenExportModal';
import ProjectCodeInputModal from 'app/components/ProjectCodeInputModal/ProjectCodeInputModal';
import ShareConfigurationModal from 'app/components/ShareConfigurationModal/ShareConfigurationModal';
import AlertModal from 'app/components/AlertModal/AlertModal';
import { saveConfiguration } from 'app/shared/util/save-configuration';
import { usePartsList } from 'app/customHooks/usePartsList';
import { useCreateDrawings } from 'app/customHooks/useProjectDrawings';
import { getManufacturerForCurrentUser } from 'app/entities/manufacturer/manufacturer.reducer';
import { setIsSidebarCollapsed, setIsFullScreenMode } from '../user-settings/user-settings.reducer';
import ThermoKoolRequestForm from 'app/components/ThermoKoolRequestFormModal/ThermoKoolRequestFormModal';
import KrowneDraftBeerSystemForm from 'app/components/KrowneDraftBeerSystemForm/KrowneDraftBeerSystemForm';
import RequestSpecPopUp from 'app/components/KrowneFaucetFlexForm/RequestSpecPopup';
import FaucetFlexForm from 'app/components/KrowneFaucetFlexForm/FaucetFlexFormPopup';
import KrowneBarFlexFormModal from 'app/components/KrowneBarflexForm/KrowneBarFlexFormModal';
import { setIsHelpVideoPopupOpen } from '../user-settings/user-settings.reducer';

export interface IRoomleProductDetailProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ product_id: string; roomle_component_id: string; mailsha1: string }> {}

export const RoomleProductDetail = (props: IRoomleProductDetailProps) => {
  const configurationsCart = useRef(null);
  const onUnmount = useRef();
  let history = useHistory();
  const savedUnitsOfMeasure = localStorage.getItem('unitsOfMeasure');
  const mailsha1 = useMemo(() => {
    return props.match.params.mailsha1 ? props.match.params.mailsha1 : MD5(props.account.email).toString();
  }, [props.match.params.mailsha1, props.account.email]);
  // buttons state
  const [isShopEquipmentButtonWasPressed, setIsShopEquipmentButtonWasPressed] = useState(false);
  const [isShareButtonWasPressed, setIsShareButtonWasPressed] = useState(false);
  const [isShareByEmailButtonWasPressed, setIsShareByEmailButtonWasPressed] = useState(false);
  const [isAddToProjectButtonWasPressed, setIsAddToProjectButtonWasPressed] = useState(false);
  const [isSaveToRevitExportsWasPressed, setIsSaveToRevitExportsWasPressed] = useState(false);
  const [isSaveToInventoryButtonWasPressed, setIsSaveToInventoryButtonWasPressed] = useState(false);
  const [isMarketplaceButtonWasPressed, setIsMarketplaceButtonWasPressed] = useState(false);
  const [isReconfigureButtonWasPressed, setIsReconfigureButtonWasPressed] = useState(false);
  const [isRequestQuotesButtonWasPressed, setIsRequestQuotesButtonWasPressed] = useState(false);

  // modal windows state
  const [isForbiddenExportModalOpen, setIsForbiddenExportModalOpen] = useState(false);
  const [isShareConfigurationByEmailModalOpen, setIsShareConfigurationByEmailModalOpen] = useState(false);
  const [isAddToProjectModalOpen, setIsAddToProjectModalOpen] = useState(false);
  const [isMarketplaceButtonsModalOpen, setIsMarketplaceButtonsModalOpen] = useState(false);
  const [isChangeConfigurationModalOpen, setIsChangeConfigurationModalOpen] = useState(false);
  const [isProjectDetailsModalOpen, setIsProjectDetailsModalOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [isCreateProjectNameModalOpen, setIsCreateProjectNameModalOpen] = useState(false);
  const [isShareConfigurationPopupOpen, setIsShareConfigurationPopupOpen] = useState(false);
  const [isThermoKoolReuqestModalOpen, setIsThermoKoolReuqestModalOpen] = useState(false);
  const [isKrowneDraftBeerSystemReuqestModalOpen, setIsKrowneDraftBeerSystemReuqestModalOpen] = useState(false);
  const [isKrowneFaucetFlexRequestSpecModalOpen, setIsKrowneFaucetFlexRequestSpecModalOpen] = useState(false);
  const [isFaucetFlexFormModalOpen, setIsFaucetFlexFormModalOpen] = useState(false);
  const [isKrowneBarFlexFormModalOpen, setIsKrowneBarFlexFormModalOpen] = useState(false);

  // other
  const [roomleConfiguration, setRoomleConfiguration] = useState(null);
  const [shareEmail, setShareEmail] = useState('');
  const roomleChildRef = useRef(null);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState(savedUnitsOfMeasure || 'inchfeet');
  const [projectsForSelect, setProjectsForSelect] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedConfigurationObject, setSelectedConfigurationObject] = useState(null);
  const [productForDetailsModal, setProductForDetailsModal] = useState(null);
  const [shareType, setShareType] = useState(null);
  const [roomleLoaded, setRoomleLoaded] = useState(false);
  const { isDetailsOpen, setisDetailsOpen, projectProductsPartsList, partsListLoading } = usePartsList([
    { userConfiguration: productForDetailsModal },
  ]);
  const {
    projectCodeValue,
    setProjectCodeValue,
    isProjectCodeInputModalOpen,
    setIsProjectCodeInputModalOpen,
    isCreateDrawingsModalOpen,
    setIsCreateDrawingsModalOpen,
    createDrawings,
    updateProjectCode,
    isEmptyFieldsData,
    setIsEmptyFieldsData,
    isNeedToFillTheDataForDrawings,
    setIsNeedToFillTheDataForDrawings,
  } = useCreateDrawings(props.currentProject, props.account, props.forgeConnect, props.updateProject, props.updateProjectDetails);

  onUnmount.current = () => {
    if (props.isCurrentProjectShouldBeClosed) {
      props.resetCurrentProject();
    }
  };

  useEffect(() => {
    props.setIsSidebarCollapsed(true);
    props.setIsFullScreenMode(true);
    props.getEntityByComponentId(props.match.params.roomle_component_id);

    if (!props.projects.length) {
      props.getAllProjectByLoginUser();
    }

    if (sessionStorage.getItem('currentProject') && Object.keys(props.currentProject).length < 4) {
      props.getProject(sessionStorage.getItem('currentProject'));
      props.setIsCurrentProjectShouldBeClosed(true);
    }

    if (props.floorplanIdForDirectAdding) {
      props.getProject(props.floorplanIdForDirectAdding);
    }

    return () => {
      props.setIsSidebarCollapsed(false);
      props.setIsFullScreenMode(false);
      onUnmount.current();
    };
  }, []);

  useEffect(() => {
    if (props.projects.length) {
      const results = props.projects.map(item => {
        return { value: item.id, label: item.projectName };
      });
      setProjectsForSelect(results);
    }
  }, [props.projects]);

  useEffect(() => {
    if (isShareByEmailButtonWasPressed) {
      const newEntity = saveConfiguration(roomleConfiguration, props.productEntity, mailsha1);
      props.createEntity(newEntity);
    }
    if (isSaveToInventoryButtonWasPressed && roomleConfiguration.configuration) {
      const newEntity = saveConfiguration(roomleConfiguration, props.productEntity, mailsha1, true);
      props.createEntity(newEntity);
      // setIsSaveToInventoryButtonWasPressed(false);
    }
    if (isSaveToRevitExportsWasPressed) {
      const newEntity = saveConfiguration(roomleConfiguration, props.productEntity, mailsha1);
      props.createRevitExport(newEntity);
      setIsSaveToRevitExportsWasPressed(false);
    }
    if (isAddToProjectButtonWasPressed) {
      const newEntity = saveConfiguration(roomleConfiguration, props.productEntity, mailsha1);
      props.createEntity(newEntity);
    }
    if (
      isRequestQuotesButtonWasPressed &&
      !isShareByEmailButtonWasPressed &&
      !isSaveToInventoryButtonWasPressed &&
      !isSaveToRevitExportsWasPressed &&
      !isAddToProjectButtonWasPressed &&
      !isShareButtonWasPressed
    ) {
      if (props.productEntity.roomleComponentId === 'krowne:draft') {
        setIsKrowneDraftBeerSystemReuqestModalOpen(true);
      } else if (props.productEntity.roomleComponentId === 'thermo-kool:walk-ins_pid') {
        setIsThermoKoolReuqestModalOpen(true);
      } else if (props.productEntity.roomleComponentId === 'krowne:faucet_pid') {
        setIsKrowneFaucetFlexRequestSpecModalOpen(true);
      } else if (props.productEntity.roomleComponentId === 'krowne:barflex_configurator') {
        setIsKrowneBarFlexFormModalOpen(true);
      }

      // setIsMarketplaceButtonsModalOpen(true);
    }
    if (isShareButtonWasPressed) {
      setIsShareConfigurationPopupOpen(true);
    }
  }, [roomleConfiguration]);

  useEffect(() => {
    if (props.updateSuccess) {
      if (isSaveToInventoryButtonWasPressed) {
        setIsSaveToInventoryButtonWasPressed(false);
      }
      if (isShopEquipmentButtonWasPressed) {
        goToMarketplace(props.userConfigurationEntity.id, props.userConfigurationEntity.manufacturerName);
        setIsShopEquipmentButtonWasPressed(false);
      }
      if (isShareByEmailButtonWasPressed) {
        props.shareConfiguration({
          shareEmail: shareEmail,
          userConfigurationId: props.userConfigurationEntity.id,
          shareType,
        });
        setIsShareByEmailButtonWasPressed(false);
        setIsShareButtonWasPressed(false);
        setIsShareConfigurationPopupOpen(false);
      }
      if (isAddToProjectButtonWasPressed) {
        if (Object.keys(props.currentProject).length > 3) {
          const sectionPrefix = JSON.parse(roomleConfiguration.configuration).parameters.sectionPrefix;
          props.updateProject({
            ...props.currentProject,
            projectProducts: [
              ...props.currentProject.projectProducts,
              {
                quantity: 1,
                itemNumber: 1,
                sectionPrefix,
                userConfiguration: props.userConfigurationEntity,
              },
            ],
          });
          setIsAddToProjectButtonWasPressed(false);
        } else if (selectedProjectId) {
          props.getProject(selectedProjectId.value);
          setSelectedProjectId(null);
        }
        setIsAddToProjectModalOpen(false);
      }
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    if (Object.keys(props.currentProject).length > 3 && isAddToProjectButtonWasPressed) {
      roomleChildRef.current.requestProductOutsideTheIframe();
    }
  }, [props.currentProject]);

  useEffect(() => {
    if (isDetailsOpen) {
      props.getManufacturerForCurrentUser();
    }
  }, [isDetailsOpen]);

  useEffect(() => {
    if (props.projectProductsUpdateSuccess) {
      props.getProject(props.currentProject.id);
    }
  }, [props.projectProductsUpdateSuccess]);

  useEffect(() => {
    if (props.projectUpdateSuccess && props.floorplanIdForDirectAdding) {
      history.push(`/edit-project/${props.floorplanIdForDirectAdding}`);
      props.setFloorPlanIdForDirectAdding(null);
    }
  }, [props.projectUpdateSuccess]);

  const getTenant = configId => configId.split(':')[0];

  const handleShopEquipmentDealersButton = () => {
    saveConfiguration(roomleConfiguration, false, props.productEntity, props.createRevitExport, props.createEntity, mailsha1, props.match);
    setIsShopEquipmentButtonWasPressed(true);
  };

  const handleSaveToInventoryButton = () => {
    roomleChildRef.current.requestProductOutsideTheIframe();
    setIsSaveToInventoryButtonWasPressed(true);
  };

  const goToMarketplace = (configurationId, configurationManufacturer) => {
    props.history.push(`/marketplace/${configurationId}/${configurationManufacturer}`);
  };

  const handleShareByEmailButton = () => {
    setIsShareConfigurationByEmailModalOpen(true);
  };

  const handleAddToProjectButton = () => {
    if (Object.keys(props.currentProject).length > 3) {
      setIsAddToProjectButtonWasPressed(true);
      createProject();
    } else {
      setIsAddToProjectModalOpen(true);
      setIsAddToProjectButtonWasPressed(true);
    }
  };

  const triggerShareButton = () => {
    roomleChildRef.current.requestProductOutsideTheIframe();
    setIsShareButtonWasPressed(true);
  };

  const shareConfigurationByEmail = () => {
    if (isShareButtonWasPressed) {
      setShareType('SUGGESTION_SHARE');
      const newEntity = saveConfiguration(roomleConfiguration, props.productEntity, mailsha1);
      props.createEntity(newEntity);
      setIsShareByEmailButtonWasPressed(true);
      setIsShareConfigurationByEmailModalOpen(false);
      setIsExportRevitModalOpen(false);
      setIsShareConfigurationPopupOpen(false);
    } else {
      setShareType('REVIT_SHARE');
      roomleChildRef.current.requestProductOutsideTheIframe();
      setIsShareByEmailButtonWasPressed(true);
      setIsShareConfigurationByEmailModalOpen(false);
      setIsExportRevitModalOpen(false);
      setIsShareConfigurationPopupOpen(false);
    }
  };

  const createProject = () => {
    roomleChildRef.current.requestProductOutsideTheIframe();
  };

  const updateUserConfigurationInsideProject = (fieldValue, userConfigurationId, fieldName) => {
    const projectCopy = Object.assign({}, props.currentProject);
    const objIndex = props.currentProject.projectProducts.findIndex(item => item.userConfiguration.id === userConfigurationId);
    projectCopy.projectProducts[objIndex][fieldName] = fieldValue;

    props.updateProject(projectCopy);
    setIsNeedToFillTheDataForDrawings(false);
  };

  const deleteConfigurationFromCart = () => {
    props.deleteProjectProduct(selectedConfigurationObject.id);

    setIsDeleteConfirmationDialogOpen(false);
    setSelectedConfigurationObject(null);
  };

  const handleConfigureButton = (product, e) => {
    if (e.target.nodeName !== 'I') {
      history.push('/');
      setTimeout(() => {
        history.push(`${mailsha1}/${product.id}/${product.roomleComponentId}`);
      }, 0);
    }
  };

  useEffect(() => {
    if (isReconfigureButtonWasPressed) {
      props.getEntityByComponentId(props.match.params.roomle_component_id);
      setIsReconfigureButtonWasPressed(false);
    }
  }, [props.match.params]);

  const handleReconfigureButton = (product, e) => {
    const configuration = product.userConfiguration.roomleComponentId;
    history.push(`/configurators/${mailsha1}/${product.id}/${configuration}/${product.userConfiguration.configuration}`);
  };

  const removeCurrentProject = () => {
    props.resetCurrentProject();
  };

  const createFloorPlanForProject = () => {
    props.updateProject({ ...props.currentProject, roomDesign: true });
    history.push(`/edit-project/${props.currentProject.id}`);
  };

  return (
    <div className="product-configurators-page-inner-container">
      {props.loadingProduct || props.loading ? null : (
        <>
          {props.currentProject.id && hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_PROJECT_DESIGNER]) && (
            <ConfigurationProjectBar
              project={props.currentProject}
              openProjectDetailsModal={() => setIsProjectDetailsModalOpen(true)}
              openCreateDrawingsModal={() => setIsCreateDrawingsModalOpen(true)}
              resetCurrentProject={removeCurrentProject}
              account={props.account}
              createFloorPlanForProject={createFloorPlanForProject}
              roomleLoaded={roomleLoaded}
              loadingValuesArray={[
                props.loading,
                props.updating,
                props.loadingProduct,
                props.loadingProjectProduct,
                props.updatingProjectProduct,
                props.projectUpdating,
              ]}
            />
          )}
          <RoomleConfigurationBar
            setUnitsOfMeasure={setUnitsOfMeasure}
            setIsChangeConfigurationModalOpen={setIsChangeConfigurationModalOpen}
            setIsCurrentProjectShouldBeClosed={props.setIsCurrentProjectShouldBeClosed}
            productEntity={props.productEntity}
            setIsExportRevitModalOpen={setIsShareConfigurationByEmailModalOpen}
            setIsShareButtonWasPressed={setIsShareButtonWasPressed}
            setIsForbiddenExportModalOpen={setIsForbiddenExportModalOpen}
            setIsHelpVideoPopupOpen={props.setIsHelpVideoPopupOpen}
            loading={props.loading}
            loadingProduct={props.loadingProduct}
            updating={props.updating}
            handleAddToProjectButton={handleAddToProjectButton}
            handleSaveToInventoryButton={handleSaveToInventoryButton}
            projectUpdating={props.projectUpdating}
            account={props.account}
            handleShareByEmailButton={handleShareByEmailButton}
            triggerShareButton={triggerShareButton}
            roomleLoaded={roomleLoaded}
            prevPath={props.location.state?.prevPath}
            loadingValuesArray={[
              props.loading,
              props.updating,
              props.loadingProduct,
              props.loadingProjectProduct,
              props.updatingProjectProduct,
              props.projectUpdating,
            ]}
          />
        </>
      )}

      {props.loadingProduct || props.loading ? (
        <AppSpinner />
      ) : (
        <>
          <Roomle
            handleFinishLoading={null}
            configurationId={
              props.match.params.configuration_id ? props.match.params.configuration_id : props.match.params.roomle_component_id
            }
            clientId={getTenant(props.match.params.roomle_component_id)}
            saveButton={true}
            unitsOfMeasure={unitsOfMeasure}
            ref={roomleChildRef}
            setRoomleConfiguration={setRoomleConfiguration}
            setIsRequestQuotesButtonWasPressed={setIsRequestQuotesButtonWasPressed}
            isRequestQuotesButtonWasPressed={isRequestQuotesButtonWasPressed}
            isShowInMarketPlace={props.productEntity.showInMarketPlace}
            isInProduction={props.isInProduction}
            manufacturerName={props.productEntity.manufacturerName}
            roomleComponentId={props.productEntity.roomleComponentId}
            roomleLoaded={roomleLoaded}
            setRoomleLoaded={setRoomleLoaded}
            isSideBarCollapsed={props.isSideBarCollapsed}
            isFullScreenMode={props.isFullScreenMode}
            currentProject={props.currentProject}
            selectComponentAfterDocking={props.productEntity.selectComponentAfterDocking}
          />

          {/* <div ref={configurationsCart} style={{ height: 20, marginBottom: 30 }}></div>
          {props.updatingProjectProduct ||
          props.projectLoading ||
          props.projectUpdating ||
          props.loadingProjectProduct ||
          props.updatingProjectProduct ? (
            <div>
              <AppSpinner />
              <div style={{ marginBottom: 50 }}></div>
            </div>
          ) : (
            props.currentProject.id &&
            props.currentProject.projectProducts.length !== 0 &&
            hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_PROJECT_DESIGNER]) && (
              <ConfigurationCartTable
                project={props.currentProject}
                deleteConfigurationFromCart={deleteConfigurationFromCart}
                projectUpdating={props.projectUpdating}
                updateUserConfigurationInsideProject={updateUserConfigurationInsideProject}
                setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
                setSelectedConfigurationObject={setSelectedConfigurationObject}
                setProductForDetailsModal={setProductForDetailsModal}
                setisProductDetailsModalOpen={setisDetailsOpen}
                account={props.account}
                isNeedToFillTheDataForDrawings={isNeedToFillTheDataForDrawings}
                getUserConfiguration={props.getEntity}
                handleReconfigureButton={handleReconfigureButton}
                setIsReconfigureButtonWasPressed={setIsReconfigureButtonWasPressed}
              />
            )
          )} */}

          <ShareConfigurationByEmailModal
            isOpen={isShareConfigurationByEmailModalOpen}
            setShareEmail={setShareEmail}
            shareConfiguration={shareConfigurationByEmail}
            setIsShareConfigurationByEmailModalOpen={() => setIsShareConfigurationByEmailModalOpen(false)}
            title={isShareButtonWasPressed ? 'Share Configuration by Email' : 'Share Revit by Email'}
            isShareButtonWasPressed={isShareButtonWasPressed}
            saveTo="Save to Inventory"
            setIsHelpVideoPopupOpen={props.setIsHelpVideoPopupOpen}
          />
          <AddToProjectModal
            isOpen={isAddToProjectModalOpen}
            setIsAddToProjectModalOpen={() => {
              setIsAddToProjectModalOpen(false);
              setSelectedProjectId(null);
            }}
            projectsForSelect={projectsForSelect}
            setProjectsForSelect={setProjectsForSelect}
            setSelectedProjectId={setSelectedProjectId}
            createProject={createProject}
            projectUpdating={props.updating}
            selectedProjectId={selectedProjectId}
            setIsCreateNewProjectModalOpen={() => setIsCreateProjectNameModalOpen(true)}
          />
          <CreateProjectNameModal
            isOpen={isCreateProjectNameModalOpen}
            closeModal={() => setIsCreateProjectNameModalOpen(false)}
            closeAddToProjectModal={() => setIsAddToProjectModalOpen(false)}
            isCreateWithFloorPlan={false}
          />
          <MarketplaceButtonsModal
            handleShopEquipmentDealersButton={handleShopEquipmentDealersButton}
            loading={props.loading}
            loadingProduct={props.loadingProduct}
            isOpen={isMarketplaceButtonsModalOpen}
            setIsMarketplaceModalOpen={() => setIsMarketplaceButtonsModalOpen(false)}
          />
          <ChangeConfigurationModal
            isOpen={isChangeConfigurationModalOpen}
            closeModal={() => {
              setIsChangeConfigurationModalOpen(false);
              props.setIsCurrentProjectShouldBeClosed(true);
            }}
            setisProductDetailsModalOpen={setisDetailsOpen}
            setProductForDetailsModal={setProductForDetailsModal}
          />
          <ProjectDetailsModal
            isOpen={isProjectDetailsModalOpen}
            project={props.currentProject}
            closeModal={() => setIsProjectDetailsModalOpen()}
            updateProjectDetails={props.updateProjectDetails}
            projectLoading={props.projectLoading}
            projectUpdating={props.projectUpdating}
            projectUpdateSuccess={props.projectUpdateSuccess}
          />
          <CreateShopDrawingsModal
            isOpen={isCreateDrawingsModalOpen}
            setIsCreateDrawingsModalOpen={() => setIsCreateDrawingsModalOpen(false)}
            handleCreateDrawingsButton={createDrawings}
          />
          <ConfirmationDialog
            isOpen={isDeleteConfirmationDialogOpen}
            closeConfirmationDialog={() => setIsDeleteConfirmationDialogOpen(false)}
            confirmationInfo={`Are you sure you want to delete the
            ${selectedConfigurationObject?.userConfiguration?.productName} configuration?`}
            aditionalConfirmationInfo={<DeleteAlert />}
            confirmAction={deleteConfigurationFromCart}
          />
          <ForbiddenExportModal isOpen={isForbiddenExportModalOpen} closeModal={() => setIsForbiddenExportModalOpen(false)} />
          <InventoryDetailsModal
            isOpen={isDetailsOpen}
            product={productForDetailsModal}
            closeModal={() => {
              setisDetailsOpen(false);
              setProductForDetailsModal(null);
            }}
            redirectToDetails={handleConfigureButton}
            projectProductsPartsList={projectProductsPartsList}
            partsListLoading={partsListLoading}
            userManufacturer={props.userManufacturer}
          />
          <ProjectCodeInputModal
            isOpen={isProjectCodeInputModalOpen}
            closeModal={setIsProjectCodeInputModalOpen}
            projectCodeValue={projectCodeValue}
            setProjectCodeValue={setProjectCodeValue}
            updateProjectCode={updateProjectCode}
          />
          <ShareConfigurationModal
            isOpen={isShareConfigurationPopupOpen}
            closeModal={() => {
              setIsShareConfigurationPopupOpen(false);
              setIsShareButtonWasPressed(false);
            }}
            isInProduction={props.isInProduction}
            handleShareByEmailButton={handleShareByEmailButton}
            roomleConfiguration={roomleConfiguration}
          />
          <ThermoKoolRequestForm
            isOpen={isThermoKoolReuqestModalOpen}
            closeModal={setIsThermoKoolReuqestModalOpen}
            roomleConfiguration={roomleConfiguration}
          />
          <KrowneDraftBeerSystemForm
            isOpen={isKrowneDraftBeerSystemReuqestModalOpen}
            closeModal={setIsKrowneDraftBeerSystemReuqestModalOpen}
            roomleConfiguration={roomleConfiguration}
          />
          <KrowneBarFlexFormModal
            isOpen={isKrowneBarFlexFormModalOpen}
            closeModal={() => setIsKrowneBarFlexFormModalOpen(false)}
            roomleConfiguration={roomleConfiguration}
          />
          <RequestSpecPopUp
            isOpen={isKrowneFaucetFlexRequestSpecModalOpen}
            closeModal={() => setIsKrowneFaucetFlexRequestSpecModalOpen(false)}
            roomleConfiguration={roomleConfiguration}
            setIsFaucetFlexFormModalOpen={setIsFaucetFlexFormModalOpen}
          />
          <FaucetFlexForm
            isOpen={isFaucetFlexFormModalOpen}
            closeModal={() => setIsFaucetFlexFormModalOpen(false)}
            roomleConfiguration={roomleConfiguration}
          />
          <AlertModal
            isOpen={isEmptyFieldsData}
            closeConfirmationDialog={() => {
              setIsEmptyFieldsData(false);
              setIsCreateDrawingsModalOpen(false);
              setIsNeedToFillTheDataForDrawings(true);
            }}
            confirmationInfo={
              hasAnyAuthority(props.account.authorities, [AUTHORITIES.ROLE_CORONET_DRAWING])
                ? 'You must fill in the Section Prefix and the Type to continue.'
                : 'You must fill in the Section Prefix and the Item Number to continue.'
            }
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  userConfigurationEntity: storeState.userConfiguration.entity,
  productEntity: storeState.product.entity,
  loading: storeState.userConfiguration.loading,
  updating: storeState.userConfiguration.updating,
  loadingProduct: storeState.product.loading,
  loadingProjectProduct: storeState.projectProduct.loading,
  updatingProjectProduct: storeState.projectProduct.updating,
  projectProductsUpdateSuccess: storeState.projectProduct.updateSuccess,
  projectLoading: storeState.project.loading,
  projectUpdating: storeState.project.updating,
  projectUpdateSuccess: storeState.project.updateSuccess,
  account: storeState.authentication.account,
  projects: storeState.project.entities,
  currentProject: storeState.project.entity,
  updateSuccess: storeState.userConfiguration.updateSuccess,
  isCurrentProjectShouldBeClosed: storeState.project.isProjectShouldBeClosed,
  isInProduction: storeState.applicationProfile.inProduction,
  userManufacturer: storeState.manufacturer.entity,
  isSideBarCollapsed: storeState.userSettings.isSidebarCollapsed,
  isFullScreenMode: storeState.userSettings.isFullScreenMode,
  floorplanIdForDirectAdding: storeState.project.floorplanIdForDirectAdding,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getEntityByComponentId,
  shareConfiguration,
  createRevitExport,
  createProject,
  getAllProjectByLoginUser,
  updateProject,
  updateProjectDetails,
  resetCurrentProject,
  forgeConnect,
  getProject,
  setIsCurrentProjectShouldBeClosed,
  deleteProjectProduct,
  getManufacturerForCurrentUser,
  setIsSidebarCollapsed,
  setIsFullScreenMode,
  setIsHelpVideoPopupOpen,
  setFloorPlanIdForDirectAdding,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RoomleProductDetail);
